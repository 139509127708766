<template>
  <div
    v-if="active"
    :class="[
      'relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 cursor-pointer select-none',
      'bg-blue-600',
      'text-white',
    ]"
  >
    {{ current_value }}
  </div>
  <div
    v-else
    :class="[
      'relative inline-flex items-center px-4 py-2 text-sm font-semibold ring-1 ring-inset text-gray-500 ring-gray-300 hover:bg-gray-200 focus:z-20 focus:outline-offset-0 cursor-pointer select-none',
    ]"
  >
    {{ current_value }}
  </div>
</template>

<script>
export default {
  name: "ActiveElement",
  data() {
    return {};
  },
  props: {
    current_value: {
      type: Number,
      default: 1,
    },
    type_color: {
      type: String,
      default: "main",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
