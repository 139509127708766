<template>
  <div class="flex items-center justify-start">
    <a :href="url" class="flex items-center justify-center px-4 py-2 btn select-none text-medium-xs text-gray-600 cursor-pointer hover:border-gray-600 hover:text-gray-800">
      <i class="fal fa-cogs mr-1"></i>
      {{ label }}
    </a>
  </div>
</template>

<script>
export default {
  name: "ShowButton",
  data() {
    return {};
  },
  props: {
    label: {
      type: String,
      default: "Show",
    },
    url: {
      type: String,
      required: true,
    },
  },
};
</script>
