<template>
  <slot :notPendingTask="notPendingTask" :setPendingTask="setPendingTask" v-bind="{ has_pending_task, pending_task }" />
</template>
<script>
export default {
  data() {
    return {
      has_pending_task: true,
      pending_task: null,
    };
  },
  methods: {
    notPendingTask() {
      this.has_pending_task = false;
    },
    setPendingTask(pt) {
      this.pending_task = pt;
    },
  },
};
</script>
