<template>
  <div v-if="!pending_task && last_finished_task" class="flex justify-center pt-4">
    <div class="w-full md:w-8/12 border rounded-md border-gray-300 shadow-md">
      <div class="p-4 bg-white space-y-2 rounded-md">
        <div v-if="finished_task_errors.length == 0">
          <div class="p-2 rounded bg-green-100 text-sm font-bold text-green-900 space-y-1">
            Carga realizada sin errores
            <div>
              <dat-text small> Carga realizada el {{ decoratorDateTime(last_finished_task.finished_at) }} </dat-text>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="p-2 rounded bg-red-100 text-sm font-bold text-red-900 space-y-1">
            Carga realizada con errores
            <div>
              <dat-text small> Carga realizada el {{ decoratorDateTime(last_finished_task.finished_at) }} </dat-text>
            </div>
          </div>
          <div class="pt-2">
            <basic-pagination :items="filteredRows">
              <template #content_pagination="{ paginatedItems }">
                <data-table
                  :headers="headers"
                  :rows="paginatedItems"
                  :unfilteredRows="unfilteredRows"
                  :filteredRows="filteredRows"
                  @run-rows-filter="(newRows) => runRowsFilter(newRows)"
                  @sort-rows-filter="(newRows) => sortRowsFilter(newRows)"
                ></data-table>
              </template>
            </basic-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

let token = document.getElementsByName("csrf-token")[0].getAttribute("content");
axios.defaults.headers.common["X-CSRF-Token"] = token;
axios.defaults.headers.common["Accept"] = "application/json";

export default {
  emits: ["process-not-pending-task", "process-pending-task"],
  data() {
    return {
      pending_task: null,
      bj_task_id: null,
      bj_task: null,
      finished_task: null,
      interval_finished_task: null,
      last_finished_task: null,
      finished_task_errors: [],
      filtered_task_errors: [],
      search_task_errors: [],
      toggle_task_errors: false,
    };
  },
  props: ["pending_upload_task_route", "last_finished_upload_task_route"],
  computed: {
    headers() {
      let header = [
        { key: "position", label: "#" },
        { key: "location", label: "Línea" },
        { key: "description", label: "Error" },
      ];
      return header;
    },
    filteredRows() {
      return this.formatRow(this.filtered_task_errors);
    },
    unfilteredRows() {
      return this.formatRow(this.finished_task_errors);
    },
    countSearchAuthorizationEventMembers() {
      return this.search_task_errors.filter((search_task_error) => search_task_error.search_match).length;
    },
  },
  methods: {
    formatRow(finished_task_errors) {
      return finished_task_errors.map((finished_task_error, index) => {
        return {
          id: index + 1,
          position: index + 1,
          location: finished_task_error.location,
          description: finished_task_error.description,
        };
      });
    },
    runRowsFilter(newRows) {
      this.filtered_task_errors = this.finished_task_errors.filter((finished_task_error) => {
        return newRows.some((newRow) => newRow.id === finished_task_error.id);
      });
    },
    sortRowsFilter(newRows) {
      const recordsMap = new Map(this.filtered_task_errors.map((item) => [item.id, item]));
      this.filtered_task_errors = newRows.map((row) => recordsMap.get(row.id)).filter((item) => item);
    },
    async checkPendingTask() {
      try {
        let response = await axios.get(this.pending_upload_task_route);
        if (response.data.bj_task != null) {
          this.pending_task = true;
          this.bj_task_id = response.data.bj_task.id;
          this.bj_task = response.data.bj_task;
        } else {
          this.pending_task = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkFinishedTask() {
      if (this.bj_task_id != null && !this.finished_task) {
        try {
          let response = await axios.get(`/bj/tasks/json/info_task/${this.bj_task_id}/finished`);
          this.finished_task = response.data.finished;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getLastFinishedTask() {
      try {
        let response = await axios.get(this.last_finished_upload_task_route);
        this.last_finished_task = response.data.bj_task;
      } catch (error) {
        console.log(error);
      }
    },
    async getLastFinishedTaskErrors() {
      try {
        let response = await axios.get(`/bj/task_errors/json/list_by_task/${this.last_finished_task.id}`);
        this.finished_task_errors = response.data.bj_task_errors;
        this.filtered_task_errors = response.data.bj_task_errors;
        this.search_task_errors = response.data.bj_task_errors;
      } catch (error) {
        console.log(error);
      }
    },
    emit_not_pending_task: function () {
      this.$emit("process-not-pending-task");
    },
    emit_pending_task: function () {
      this.$emit("process-pending-task", this.bj_task);
    },
  },
  watch: {
    pending_task: function () {
      if (this.pending_task) {
        this.interval_finished_task = setInterval(
          function () {
            this.checkFinishedTask();
          }.bind(this),
          5000
        );
        this.emit_pending_task();
      } else if (this.pending_task == false) {
        this.getLastFinishedTask().then(() => {
          this.emit_not_pending_task();
        });
      }
    },
    finished_task: function () {
      if (this.finished_task) {
        this.pending_task = false;
        clearInterval(this.interval_finished_task);
        this.getLastFinishedTask();
      }
    },
    last_finished_task: function () {
      if (this.last_finished_task) {
        this.getLastFinishedTaskErrors();
      }
    },
  },
  mounted: function () {
    this.checkPendingTask();
  },
  destroyed: function () {
    clearInterval(this.interval_finished_task);
  },
};
</script>
