<template>
  <basic-pagination :items="filteredRows">
    <template #content_pagination="{ paginatedItems }">
      <data-table
        :headers="headers"
        :rows="paginatedItems"
        :unfilteredRows="unfilteredRows"
        :filteredRows="filteredRows"
        @run-rows-filter="(newRows) => runRowsFilter(newRows)"
        @sort-rows-filter="(newRows) => sortRowsFilter(newRows)"
      >
        <template #body="{ row, row_key, column, row_index, original_row }">
          <slot name="body" v-bind="{ row: row, row_key: row_key, column: column, row_index: row_index, original_row }">
            <dat-text :small="true">{{ row }}</dat-text>
          </slot>
        </template>
      </data-table>
    </template>
  </basic-pagination>
</template>

<script>
export default {
  name: "ShowFamilies",
  data() {
    return {
      filtered_records: this.records,
      search_records: this.records,
    };
  },
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredRows() {
      return this.formatRow(this.filtered_records);
    },
    unfilteredRows() {
      return this.formatRow(this.records);
    },
    countSearchAuthorizationEventMembers() {
      return this.search_records.filter((search_record) => search_record.search_match).length;
    },
  },
  methods: {
    formatRow(records) {
      let keys = this.headers.map((item) => item.key);
      return records.map((record, index) => {
        const dynamicObject = {};
        dynamicObject["id"] = record.id;
        keys.forEach((key) => {
          if (key === "position") {
            dynamicObject["position"] = index + 1;
          } else {
            if (key.includes("date")){
              dynamicObject[key] = this.dateFormatter(record[key]);
            } else if (key.includes("_at")){
              dynamicObject[key] = this.dateTimeFormatter(record[key]);
            }else{
              dynamicObject[key] = record[key];
            }
          }
        });
        return dynamicObject;
      });
    },
    runRowsFilter(newRows) {
      this.filtered_records = this.records.filter((filtered_record) => {
        return newRows.some((newRow) => newRow.id === filtered_record.id);
      });
    },
    sortRowsFilter(newRows) {
      const recordsMap = new Map(this.filtered_records.map((item) => [item.id, item]));
      this.filtered_records = newRows.map((row) => recordsMap.get(row.id)).filter((item) => item);
    },
  },
};
</script>
