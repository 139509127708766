import CheckboxField from "./CheckboxField.vue";
import DateField from "./DateField.vue";
import InputField from "./InputField.vue";
import SelectSearchField from "./SelectSearchField.vue";
import ToggleField from "./ToggleField.vue";

export default {
  install: (App) => {
    App.component("checkbox-field", CheckboxField);
    App.component("date-field", DateField);
    App.component("input-field", InputField);
    App.component("select-search-field", SelectSearchField);
    App.component("toggle-field", ToggleField);
  },
};