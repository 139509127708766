<template>
  <div :class="[invalid ? 'text-red-600' : 'text-gray-600', { 'opacity-60': disabled }]">
    <slot name="label">
      <label v-if="label" :class="[invalid ? 'form-label-invalid' : 'form-label']">
        {{ label }}
      </label>
    </slot>
    <v-date-picker
      v-model="selected_date"
      locale="es"
      :attributes="highlight_today_date"
      :min-date="formatMinDate()"
      :max-date="formatMaxDate()"
      hide-time-header
      :mode="mode"
      :timezone="timezone"
      :time-accuracy="2"
      :format="format"
      :masks="masks"
      is24hr
      :rules="rules"
    >
      <template v-slot="{ inputValue, togglePopover }">
        <div class="relative rounded-md shadow-sm">
          <input @click="toggleDate(togglePopover)" type="text" :value="inputValue" :class="[invalid ? 'input-date-piker-is-invalid' : 'input-date-piker']" readonly />
          <div :class="['absolute inset-y-0 right-0 flex items-center cursor-pointer', invalid ? 'text-red-500' : 'text-gray-400']">
            <i v-if="inputValue" @click="selected_date = null" class="fal fa-times w-5 pr-1"></i>
            <i @click="togglePopover" class="fal fa-calendar w-5 pr-2"></i>
          </div>
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import moment from "moment";
import "moment/dist/locale/es";
const date_picker_value = {
  datetime: {
    format: "DD/MM/YYYY HH:mm:ss",
    masks: { input: "DD/MM/YYYY HH:mm:ss" },
  },
  date: {
    format: "DD/MM/YYYY",
    masks: { input: "DD/MM/YYYY" },
  },
  time: {
    format: "HH:mm",
    masks: { input: "HH:mm" },
  },
};
export default {
  name: "DateField",
  data() {
    return {
      selected_date: this.convertToTimezone(this.modelValue),
      highlight_today_date: [
        {
          key: "today",
          highlight: {
            color: "blue",
            fillMode: "light",
            contentClass: "italic",
          },
          dates: new Date(),
        },
      ],
      timezone: "America/Lima",
    };
  },
  props: {
    modelValue: {
      type: [String, Date],
      default: null,
    },
    defaultNow: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "date",
    },
    label: {
      type: String,
      required: false,
    },
    min_date: {
      type: [String, Date],
      required: false,
    },
    max_date: {
      type: [String, Date],
      required: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    format: function () {
      return date_picker_value[this.mode].format;
    },
    masks: function () {
      return date_picker_value[this.mode].masks;
    },
    rules: function () {
      return this.mode === "time" ? { seconds: 0 } : {};
    },
  },
  methods: {
    convertToTimezone(date) {
      return date ? moment.tz(date, this.timezone).format("YYYY-MM-DD HH:mm:ss") : this.mode === "time" ? new Date(new Date().setSeconds(0,0)) : this.defaultNow ? new Date() : null;
    },
    formatMinDate() {
      return this.min_date ? moment.tz(this.min_date, null).format("YYYY-MM-DD HH:mm:ss") : null;
    },
    formatMaxDate() {
      return this.max_date ? moment.tz(this.max_date, null).format("YYYY-MM-DD HH:mm:ss") : null;
    },
    toggleDate: function (togglePopover) {
      if (this.disabled) return;
      togglePopover();
    },
  },
  updated() {
    this.selected_date = this.modelValue;
  },
  watch: {
    selected_date: {
      handler(newValue) {
        this.$emit("update:modelValue", newValue);
      },
      immediate: true,
    },
  },
};
</script>
