<template>
  <div v-if="globalfilter && filterType === 'internal'" class="sm:flex items-center justify-end pb-2">
    <filter-search class="basis-1/2" v-model="globalInternalFilter" :placeholder="placeholder_search" type_color="default" rounded="rounded-lg" />
  </div>
  <div class="overflow-auto">
    <div class="w-full h-full">
      <div class="inline-block min-w-full p-0.5 align-middle">
        <div class="shadow-md ring-1 ring-black ring-opacity-5 rounded-lg max-h-full bg-white">
          <table class="min-w-full">
            <thead>
              <tr :class="[{ 'divide-x': delimitColumns }]">
                <template v-for="(header, h_idx) in headers" :key="header.key">
                  <th
                    :class="[
                      'px-6 text-left bg-gray-100 cursor-pointer align-top select-none whitespace-nowrap',
                      columnFilter ? 'pt-4' : 'py-2',
                      { 'rounded-tl-lg': h_idx === 0 },
                      { 'rounded-tr-lg': h_idx === headers.length - 1 },
                    ]"
                    @click.prevent="sort(header)"
                  >
                    <div class="flex justify-between space-x-2">
                      <dat-text :bold="true">{{ header.label }}</dat-text>
                      <span v-if="header.sort">
                        <span v-if="header.key === sortKey" class="text-gray-600">
                          <i v-if="sortOrder === 'asc'" class="fad fas fa-sort-up"></i>
                          <i v-else class="fad fas fa-sort-down"></i>
                        </span>
                        <span v-else class="text-gray-400">
                          <i class="fal fa-sort" />
                        </span>
                      </span>
                    </div>
                  </th>
                </template>
              </tr>
              <tr v-if="columnFilter" :class="[{ 'divide-x': delimitColumns }]">
                <template v-for="header in headers" :key="header.key">
                  <th :class="['pb-2 px-6 bg-gray-100', header.thWithIfOption]">
                    <filter-search
                      v-if="showHideFilter(header) && stringFilter(header)"
                      v-model="columnFilters[header.key]"
                      type_color="default"
                      rounded="rounded-lg"
                      :showIconSearch="false"
                    />
                    <select-search-field
                      v-if="showHideFilter(header) && optionFilter(header)"
                      v-model="columnFilters[header.key]"
                      :options="header.filter.options"
                      class="bg-white"
                    />
                    <date-field v-if="showHideFilter(header) && dateFilter(header)" v-model="columnFilters[header.key]" />
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, idx) in currentRows" :key="row.rowIndex">
                <tr :class="['border-gray-200', 'border-t', 'hover:bg-gray-100', { 'divide-x': delimitColumns }]">
                  <template v-for="(header, index) in headers" :key="header.key">
                    <td class="py-2 px-6">
                      <slot name="body" v-bind="{ original_row: row, row: row[header.key], row_key: header.key, column: header[index + 1], row_index: idx }">
                        <dat-text :small="true">{{ row[header.key] }}</dat-text>
                      </slot>
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import 'moment/dist/locale/es'
export default {
  name: "DataTable",
  emits: ["run-rows-filter", "sort-rows-filter"],
  data() {
    return {
      sortKey: null,
      sortOrder: "asc",
      columnFilters: {},
      globalInternalFilter: "",
      currentRows: this.rows,
    };
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    filteredRows: {
      type: Array,
      default: () => [],
    },
    unfilteredRows: {
      type: Array,
      default: () => [],
    },
    globalExternalFilter: {
      type: String,
      default: "",
    },
    globalfilter: {
      type: Boolean,
      default: false,
    },
    filterType: {
      type: String,
      default: "",
    },
    placeholder_search: {
      type: String,
      default: "",
    },
    sortColumn: {
      type: Boolean,
      default: true,
    },
    delimitColumns: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    globalFilterText() {
      if (!this.globalfilter) return "";
      if (this.filterType === "external") return this.globalExternalFilter;
      if (this.filterType === "internal") return this.globalInternalFilter;
      return "";
    },
    columnFilter() {
      return this.headers.some((header) => header.filter);
    },
    // Filtrar las filas según el filtro global y los filtros de columna
    runFilteredRows() {
      let filtered = [...this.unfilteredRows];

      // Aplicar filtro global
      if (this.globalFilterText) {
        const filterText = this.globalFilterText.toLowerCase();
        filtered = filtered.filter((row) => Object.values(row).some((value) => String(value).toLowerCase().includes(filterText)));
      }

      // Aplicar filtros por columna
      for (let key in this.columnFilters) {
        let header = this.fetchHeaderByKey(key);
        if (this.columnFilters[key] && this.stringFilter(header)) {
          const columnFilterText = this.columnFilters[key].toLowerCase();
          filtered = filtered.filter((row) => this.removeAccents(String(row[key])).toLowerCase().includes(this.removeAccents(columnFilterText)));
        }
        if (this.columnFilters[key] && this.optionFilter(header)) {
          const columnFilterValue = this.columnFilters[key].value;
          filtered = filtered.filter((row) => String(row[key]).toLowerCase().includes(columnFilterValue));
        }
        if (this.columnFilters[key] && this.dateFilter(header)) {
          const columnFilterDate = this.dateFormatter(this.columnFilters[key]);
          filtered = filtered.filter((row) => String(row[key]).toLowerCase().includes(columnFilterDate));
        }
      }
      return filtered;
    },
  },
  methods: {
    // Cambiar la columna de orden
    sort(header) {
      if (this.sortKey === header.key) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortKey = header.key;
        this.sortOrder = "asc";
      }
      let sortRows = this.filteredRows;

      if (this.dateFilter(header)) {
        sortRows = sortRows.sort((a, b) => {
          const aValue = moment(a[this.sortKey], "DD MMM YYYY HH:mm:ss", "es");
          const bValue = moment(b[this.sortKey], "DD MMM YYYY HH:mm:ss", "es");

          if (this.sortOrder === "asc") {
            return aValue - bValue;
          } else {
            return bValue - aValue;
          }
        });
      } else {
        sortRows = sortRows.sort((a, b) => {
          const aValue = a[this.sortKey];
          const bValue = b[this.sortKey];

          if (this.sortOrder === "asc") {
            return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
          } else {
            return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
          }
        });
      }
      this.$emit("sort-rows-filter", sortRows);
    },
    showHideFilter(header) {
      return header.filter && (typeof header.filter === "boolean" ? header.filter : true);
    },
    stringFilter(header) {
      return typeof header.filter === "boolean" || header.filter === "string";
    },
    optionFilter(header) {
      return header.filter.type === "option";
    },
    dateFilter(header) {
      return header.filter.type === "date";
    },
    fetchHeaderByKey(key) {
      return this.headers.find((header) => header.key === key);
    },
    setThWithIfOption() {
      this.headers.forEach((header) => {
        if (header.filter && header.filter.type === "option") {
          header.thWithIfOption = "min-w-36";
        }
      });
    },
  },
  updated() {
    this.currentRows = this.rows;
  },
  watch: {
    columnFilters: {
      handler() {
        this.$emit("run-rows-filter", this.runFilteredRows);
      },
      deep: true,
    },
    globalFilterText() {
      this.$emit("run-rows-filter", this.runFilteredRows);
    },
  },
  created() {
    this.setThWithIfOption();
  },
};
</script>
