<template>
  <div :class="['relative shadow-sm', rounded]">
    <div v-if="showIconSearch" class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
      <i class="fal fa-magnifying-glass text-xs text-gray-500 mr-3" />
    </div>
    <input
      type="text"
      :class="['form-field-color bg-gray-50 form-field focus:border-gray-500 focus:ring-gray-50 border-gray-300', { 'pl-8': showIconSearch }]"
      v-model="search_text"
      :placeholder="placeholder"
      :autofocus="true"
    />
    <div @click="search_text = ''" v-show="search_text.length > 0" class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" aria-hidden="true">
      <i class="fal fa-xmark text-xs text-gray-500 mr-1" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterSearch",
  data() {
    return {
      search_text: this.modelValue,
    };
  },
  props: {
    modelValue: {
      required: true,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
    },
    rounded: {
      type: String,
      default: "rounded-xl",
    },
    type_color: {
      type: String,
      default: "main",
    },
    showIconSearch: {
      type: Boolean,
      default: true,
    },
  },
  updated() {
    this.search_text = this.modelValue;
  },
  watch: {
    search_text: function (newValue) {
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>
