<template>
  <div
    class="relative inline-flex items-center px-4 py-2 text-gray-500 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0 cursor-not-allowed"
  >
    <span class="sr-only">Intermediate</span>
    ...
  </div>
</template>

<script>
export default {
  name: "IntermediateElement",
  data() {
    return {};
  },
  props: {},
};
</script>
