<template>
  <span
    :class="[
      bold
        ? small
          ? 'text-bold-xs'
          : 'text-bold-sm'
        : small
        ? 'text-normal-xs'
        : 'text-normal-sm',
    ]"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: "DatText",
  data() {
    return {};
  },
  props: {
    bold: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    type_color: {
      type: String,
      default: "default",
    },
  },
};
</script>
