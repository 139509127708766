<template>
  <div
    v-if="disabled"
    class="relative inline-flex items-center rounded-l-md px-4 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0 cursor-not-allowed opacity-75"
  >
    <span class="sr-only">Previous</span>
    <i class="fas fa-chevron-left fa-xs" />
  </div>
  <div v-else class="relative inline-flex items-center rounded-l-md px-4 py-2 text-gray-500 hover:bg-gray-200 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0 cursor-pointer">
    <span class="sr-only">Previous</span>
    <i class="fas fa-chevron-left fa-xs" />
  </div>
</template>

<script>
export default {
  name: "PreviousElement",
  data() {
    return {};
  },
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
