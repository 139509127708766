<template>
  <slot name="content_pagination" v-bind="{ paginatedItems }" />
  <div v-if="countItems > items_per_page" class="flex sm:flex-row sm:flex-1 sm:items-center sm:justify-between flex-col justify-end items-end pt-2 pb-2 px-2">
    <div>
      <p>
        <dat-text>Mostrando </dat-text>
        <dat-text :bold="true">{{ showingItems }}</dat-text>
        <dat-text> a </dat-text>
        <dat-text :bold="true">{{ toItems }}</dat-text>
        <dat-text> de </dat-text>
        <dat-text :bold="true">{{ countItems }}</dat-text>
        <dat-text> resultados</dat-text>
      </p>
    </div>
    <div>
      <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm bg-gray-50" aria-label="Pagination">
        <!-- Botón para la página anterior -->
        <previous-component @click.prevent="previousPage" :disabled="current_page === 0" />

        <!-- Siempre muestra la primera página -->
        <number-component @click.prevent="changeToPage(1)" :current_value="1" :active="current_page === 0" />

        <!-- Mostrar el primer ... si corresponde -->
        <intermediate-component v-if="showLeftEllipsis" />

        <!-- Mostrar las páginas en el medio -->
        <template v-for="page in middlePages" :key="page">
          <number-component @click.prevent="changeToPage(page)" :current_value="page" :active="page === current_page + 1" />
        </template>

        <!-- Mostrar el segundo ... si corresponde -->
        <intermediate-component v-if="showRightEllipsis" />

        <!-- Siempre muestra la última página -->
        <number-component v-if="totalPages > 1" @click.prevent="changeToPage(totalPages)" :current_value="totalPages" :active="current_page === totalPages - 1" />

        <!-- Botón para la página siguiente -->
        <next-component @click.prevent="nextPage" :disabled="current_page === totalPages - 1" />
      </nav>
    </div>
  </div>
</template>

<script>
import PreviousComponent from "@/components/shared/paginations/basic_pagination/PreviousComponent.vue";
import NextComponent from "@/components/shared/paginations/basic_pagination/NextComponent.vue";
import IntermediateComponent from "@/components/shared/paginations/basic_pagination/IntermediateComponent.vue";
import NumberComponent from "@/components/shared/paginations/basic_pagination/NumberComponent.vue";
export default {
  name: "BasicPagination",
  components: {
    PreviousComponent,
    NextComponent,
    IntermediateComponent,
    NumberComponent,
  },
  data() {
    return {
      current_page: 0,
      items_per_page: this.itemsPerPage,
      currentItems: this.items,
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 25,
    },
  },
  methods: {
    nextPage() {
      if (this.current_page < this.totalPages - 1) {
        this.current_page++;
      }
    },
    previousPage() {
      if (this.current_page > 0) {
        this.current_page--;
      }
    },
    changeToPage(page) {
      this.current_page = page - 1;
    },
  },
  computed: {
    countItems() {
      return this.currentItems.length;
    },
    showingItems() {
      return this.startItem + 1;
    },
    toItems() {
      return this.finishItem <= this.countItems ? this.finishItem : this.countItems;
    },
    startItem() {
      return this.current_page * this.items_per_page;
    },
    finishItem() {
      return this.startItem + this.items_per_page;
    },
    paginatedItems() {
      return this.currentItems.slice(this.startItem, this.finishItem);
    },
    totalPages() {
      return Math.ceil(this.countItems / this.items_per_page);
    },
    middlePages() {
      const pages = [];
      if (this.totalPages <= 6) {
        // Si hay 6 o menos páginas, simplemente mostramos todas las páginas
        for (let i = 2; i < this.totalPages; i++) {
          pages.push(i);
        }
      } else if (this.current_page <= 3) {
        // Si la página actual está entre 1 y 4, mostramos las primeras 4 páginas
        for (let i = 2; i <= 4; i++) {
          pages.push(i);
        }
      } else if (this.current_page >= this.totalPages - 4) {
        // Si la página actual está entre las últimas 4, mostramos las últimas 4 páginas
        for (let i = this.totalPages - 3; i < this.totalPages; i++) {
          pages.push(i);
        }
      } else {
        // Para las páginas intermedias, mostramos la página actual y sus vecinas
        for (let i = this.current_page; i <= this.current_page + 1; i++) {
          pages.push(i + 1);
        }
      }
      return pages;
    },
    showLeftEllipsis() {
      return this.current_page > 3 && this.totalPages > 6;
    },
    showRightEllipsis() {
      return this.current_page < this.totalPages - 4 && this.totalPages > 6;
    },
  },
  updated() {
    this.currentItems = this.items;
  },
  watch: {
    items() {
      this.current_page = 0;
    },
  },
};
</script>
