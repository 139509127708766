import CancelButton from "./CancelButton.vue"
import DangerButton from "./DangerButton.vue"
import DangerButtonGhost from "./DangerButtonGhost.vue"
import DangerCancelButton from "./DangerCancelButton.vue"
import DefaultButton from './DefaultButton.vue'
import DestroyButton from "./DestroyButton.vue"
import EditButtonLink from "./EditButtonLink.vue"
import EditButton from "./EditButton.vue"
import PrimaryButton from "./PrimaryButton.vue"
import PrimaryButtonDisabled from "./PrimaryButtonDisabled.vue"
import SaveButton from "./SaveButton.vue"
import SaveButtonLink from "./SaveButtonLink.vue"
import ShowButton from "./ShowButton.vue"
import SecondaryButton from "./SecondaryButton.vue"
import TabButton from "./TabButton.vue"

export default {
  install: (App) => {
    App.component("cancel-button", CancelButton);
    App.component("danger-button", DangerButton);
    App.component("danger-button-ghost", DangerButtonGhost);
    App.component("danger-cancel-button", DangerCancelButton);
    App.component("default-button", DefaultButton);
    App.component("destroy-button", DestroyButton);
    App.component("edit-button-link", EditButtonLink);
    App.component("edit-button", EditButton);
    App.component("primary-button", PrimaryButton);
    App.component("primary-button-disabled", PrimaryButtonDisabled);
    App.component("save-button", SaveButton);
    App.component("save-button-link", SaveButtonLink);
    App.component("show-button", ShowButton);
    App.component("secondary-button", SecondaryButton);
    App.component("tab-button", TabButton);
  },
};